<div style="margin-left:8px;">
    <h3 id="staffListLabel">Staff</h3>
    <div *ngIf="!permissionLock" (click)="addStaff()" class="add-on mt-1"><div class="add-icon">+</div><span class="ng-tns-c4-3">Add staff</span></div>
</div>
<table class="table table-select-list" id="staff-list" aria-describedby="staffListLabel">
    <thead>
        <tr>
            <th scope="col">
                <sortable-header field="firstName" label="First Name" [sortManager]="staffManager"></sortable-header>
            </th>
            <th scope="col">
                <sortable-header field="lastName" label="Last Name" [sortManager]="staffManager"></sortable-header>
            </th>
            <th scope="col">
                <sortable-header field="position" label="Position" [sortManager]="staffManager"></sortable-header>
            </th>
            <th scope="col" class="text-right">
                <button type="button" class="btn btn-default" (click)="filterToggle()" [ngClass]="{'active btn-primary':showInactive}">
                    <span *ngIf="showInactive"> Hide</span><span *ngIf="!showInactive"> Show</span> Inactive
                </button>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr [ngClass]="{'disabled':permissionLock}" class="staff-list-item" *ngFor="let staff of model | async | inactiveUser : showInactive" (click)="editStaff(staff._id)">
            <td scope="row" [ngClass]="{'muted': !staff.isActive}">
                <div>
                    {{ staff.firstName }}
                </div>
                <div class="x-small text-uppercase">
                    {{ staff.tradeLevel }}
                </div>
            </td>
            <td scope="row" [ngClass]="{'muted': !staff.isActive}">
                {{ staff.lastName }}
            </td>
            <td scope="row" [ngClass]="{'muted': !staff.isActive}">
                {{ staff.position }}
            </td>
            <td scope="row" class="currency-display">
                <!-- <button class="btn btn-danger" type="button" (click)="deleteStaff(staff, $event)">Remove</button> -->
                <div *ngIf="staff.isActive && !permissionLock" class="action-link delete" (click)="deactivateStaff(staff, $event)">Deactivate</div>
                <div *ngIf="!staff.isActive && !permissionLock" class="action-link" (click)="activateStaff(staff, $event)">Activate</div>
                <div *ngIf="staff.isActive && !permissionLock" class="">&nbsp;</div>
                <div *ngIf="!staff.isActive && permissionLock" class="">Inactive</div>
            </td>
        </tr>
    </tbody>
</table>
