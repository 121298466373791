import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManager } from './../../state/stateManager';
import { BaseSmartComponent } from './../base.component';
import { MessageService } from './../../services/message.service';

import { ICustomerModel } from './../../models/customer.models';
import { IJobModel } from './../../models/job.models';
import { IWorkOrderModel } from './../../models/workOrder.models';
import { IPurchaseOrderModel } from './../../models/purchaseOrder.models';
import { IChangeOrderModel } from './../../models/changeOrder.models';
import { IInvoiceModel } from './../../models/invoice.models';
import { IPayableModel } from '@/models/payable.models';

interface IBreadcrumbModel {
	label: string;
	route: Array<string>;
};

@Component({
	selector:'job-window',
	templateUrl:'./jobWindow.template.html',
	host: {'class': 'job-window-component'}
})
export class JobWindowComponent extends BaseSmartComponent implements OnInit {
	public breadcrumbs = new Array<IBreadcrumbModel>();	// maybe this can be a normal object...?

	private _customer: Observable<ICustomerModel>;
	private _job: Observable<IJobModel>;
	private _workOrder = new BehaviorSubject<IWorkOrderModel>(null);
	private _purchaseOrder = new BehaviorSubject<IPurchaseOrderModel>(null);
	private _changeOrder = new BehaviorSubject<IChangeOrderModel>(null);
	private _invoice = new BehaviorSubject<IInvoiceModel>(null);
	private _payable = new BehaviorSubject<IPayableModel>(null);
	private _jobEndpoint = new BehaviorSubject<string>(null);

	constructor(private route: ActivatedRoute, private router: Router, private state: StateManager, messageService: MessageService) {
		super(messageService);
	}

	ngOnInit() : void {
		const self = this;

		// always have a customer and job breadcrumb
		self._customer = self.state.getActiveCustomer();
		self._job = self.state.getActiveJob();

		// may be looking at a sub object
		self.watchSubscription(self.state.getActiveWorkOrder().subscribe((s) => {
			self._workOrder.next(s);
			self._jobEndpoint.next(null);
		}));

		self.watchSubscription(self.state.getActivePurchaseOrder().subscribe((s) => {
			self._purchaseOrder.next(s);
			self._jobEndpoint.next(null);
		}));

		self.watchSubscription(self.state.getActiveChangeOrder().subscribe((s) => {
			self._changeOrder.next(s);
			self._jobEndpoint.next(null);
		}));

		self.watchSubscription(self.state.getActiveInvoice().subscribe((s) => {
			self._invoice.next(s);
			self._jobEndpoint.next(null);
		}));

		self.watchSubscription(self.state.getActivePayable().subscribe((s) => {
			self._payable.next(s);
			self._jobEndpoint.next(null);
		}));

		// may be at a different endpoint of the job
		self.watchSubscription(self.router.events.subscribe((s:any) => {
			//TODO: better way to detect other job route endpoints
			if(s.url){
				if((s.url.indexOf('Costs') > -1) && (s.url.indexOf('PurchaseOrders') == -1)) {
					self._jobEndpoint.next('Costs');
				}
				else if(s.url.indexOf('TimeEntry') > -1) {
					self._jobEndpoint.next('Time Entry');
				}
				else if(s.url.indexOf('Warranty') > -1) {
					self._jobEndpoint.next('Warranty');
				}
				else if(s.url.indexOf('LaborCodes') > -1) {
					self._jobEndpoint.next('Labor Codes');
				}
				else if(s.url.indexOf('JobDocuments') > -1) {
					self._jobEndpoint.next('Documents');
				}
				else {
					self._jobEndpoint.next(null);
				}
			}
		}));

		// now watch all objects and arrange the breadcrumbs
		self.watchSubscription(combineLatest([
			self._customer,
			self._job,
			self._jobEndpoint,
			self._workOrder,
			self._purchaseOrder,
			self._changeOrder,
			self._invoice,
			self._payable
		], (customer: ICustomerModel, job: IJobModel, endpoint: string, workOrder: IWorkOrderModel, purchaseOrder: IPurchaseOrderModel, changeOrder: IChangeOrderModel, invoice: IInvoiceModel, payable: IPayableModel) => {
			let newBreadcrumbs = new Array<IBreadcrumbModel>();
			newBreadcrumbs.push({
				label: customer['name'],
				route: ['Customers', customer['_id'], 'Edit', 'Details']
			});

			newBreadcrumbs.push({
				label: job['number'] + ': ' + job['name'],
				route: ['Customers', customer['_id'], 'Job', job['_id'], 'Edit']
			});

			if(endpoint) {
				newBreadcrumbs.push({ label: endpoint, route: null });
			}
			else if(workOrder) {
				newBreadcrumbs.push({ label: 'Work Orders', route: null });

				if(workOrder.number) {
					newBreadcrumbs.push({
						label: workOrder['number'] + ': ' + workOrder['name'],
						route: ['Customers', customer['_id'], 'Job', job['_id'], 'WorkOrders', workOrder['_id'], 'Edit']
					});
				}
			}
			else if(purchaseOrder) {
				newBreadcrumbs.push({ label: 'Purchase Orders', route: null });

				if(purchaseOrder.number) {
					newBreadcrumbs.push({
						label: purchaseOrder['number'],
						route: ['Customers', customer['_id'], 'Job', job['_id'], 'PurchaseOrders', purchaseOrder['_id'], 'Edit']
					});
				}
			}
			else if(changeOrder) {
				newBreadcrumbs.push({ label: 'Change Orders', route: null });

				if(changeOrder.number) {
					newBreadcrumbs.push({
						label: changeOrder['number'] + (changeOrder['description'] ? ': ' + changeOrder['description'] : ''),
						route: ['Customers', customer['_id'], 'Job', job['_id'], 'ChangeOrders', changeOrder['_id'], 'Edit']
					});
				}
			}
			else if(invoice) {
				newBreadcrumbs.push({ label: 'Receivables', route: null });

				if(invoice.invoiceNumber) {
					newBreadcrumbs.push({
						label: invoice['invoiceNumber'],
						route: ['Customers', customer['_id'], 'Job', job['_id'], 'Invoices', invoice['_id'], 'Edit']
					});
				}
			}
			else if(payable) {
				newBreadcrumbs.push({ label: 'Payables', route: null });

				if(payable.invoiceNumber) {
					newBreadcrumbs.push({
						label: payable['invoiceNumber'],
						route: ['Customers', customer['_id'], 'Job', job['_id'], 'Payables', payable['_id'], 'Edit']
					});
				}
			}

			return newBreadcrumbs;
		})
		.subscribe((s) => {
			setTimeout(()=> {
				self.breadcrumbs = s;
			})
		}));
	}

	navigateToBreadcrumb(breadcrumb: IBreadcrumbModel) : void {
		if(!breadcrumb.route) { return; }

		this.router.navigate(breadcrumb.route, { relativeTo: this.route.root });
	}
};
