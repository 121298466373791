<nav style="position:relative; left: -1rem;">
    <ul class="nav nav-tabs">
        <li>
            <a [routerLink]="['Edit']" routerLinkActive="active">Details</a>
        </li>
		<li>
            <a [routerLink]="['Documents']" routerLinkActive="active">Documents</a>
        </li>
    </ul>
</nav>

<!-- <print-menu [printOptions]="printOptions" [formChanged]="formChanged" (onPrint)="print()" style="top:36px;"></print-menu> -->

<router-outlet></router-outlet>
