<div id="customer-summary">
<h2 class="customer-name">{{ model.name }}</h2>

<dl class="customer-summary-info">
	<dt>Phone</dt>
	<dd>{{ model.main | phoneFormat }}</dd>

	<dt>Address</dt>
	<dd>
		<address>
			<span class="street1">{{ model.address.address1 }}</span>
			<span class="street2">{{ model.address.address2 }}</span>
			<span class="city">{{ model.address.city }}</span>,
			<span class="state">{{ model.address.state }}</span>
			<span class="zip">{{ model.address.zip | zipFormat }}</span>
		</address>
	</dd>

	<!-- <dt>Jobs</dt>
	<dd></dd>

	<dt>Active Jobs</dt>
	<dd></dd> -->
</dl>
</div>
